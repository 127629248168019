<template>
	<main>
		<h1>Submission progress</h1>
		<ResponsiveTable ref="table" :headers="['User', 'Group', 'Submitted', 'Graded', 'Score', '']" :rows="submissions" :pages="pages" @page="updateSubmissions" />
	</main>
</template>

<script>
	import {roleGuard} from '../../inc/auth';
	import Backend from '../../inc/backend';
	import ResponsiveTable from '../../components/ResponsiveTable';
	import {DateTime} from 'luxon';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			ResponsiveTable
		},
		props: {
			sessionId: {
				type: String,
				required: false
			}
		},
		data() {
			return {
				pages: 1,
				submissions: []
			};
		},
		created() {
			this.updateSubmissions(1);
		},
		methods: {
			resetProgress(session, group) {
				this.$modal.show('dialog', {
					title: 'Reset progress?',
					text: 'This will permanently reset the users progress and delete all submitted answers for this unit. Do you want to proceed?',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Reset',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.delete(`sessions/${session}/groups/${group}`).then(() => this.updateSubmissions());

								this.$modal.hide('dialog');
							}
						}
					]
				});
			},
			updateSubmissions(page) {
				return Backend.get(`sessions/${this.sessionId || 'all'}/groups`, {params: {page: page || 1, course: this.$route.params.courseId}})
					.then(res => {
						const {groups, pages} = res.data;

						this.pages = pages;

						this.submissions = groups.map(submission => [
							{label: (submission.user ? (submission.user.firstName + ' ' + submission.user.lastName) : 'Unknown')},
							{label: submission.course.name + '<br />' + submission.group.name},
							{label: (submission.timeDone ? DateTime.fromMillis(submission.timeDone).toFormat('yyyy-MM-dd') : 'No')},
							{label: (submission.timeGraded ? DateTime.fromMillis(submission.timeGraded).toFormat('yyyy-MM-dd') : 'No')},
							{label: (submission.score || 0) + ' / ' + (submission.maxScore || 0)},
							[
								{label: 'Reset', onClick: () => this.resetProgress(submission.sessionId, submission.group.id), type: 'secondary'},
								submission.timeDone && {label: 'Grade', href: {name: 'AdminGradeSubmission', query: {
									sessionId: submission.sessionId,
									courseId: submission.course.id,
									groupId: submission.group.id
								}}}
							]
						]);
					});
			}
		}
	};
</script>